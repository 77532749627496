:root {
  --color-accent:   #7DBBFD;
  --gradient-accent: linear-gradient(260deg, #009FE3 -54%, #386FC0 112%);

  --color-light:    #fff;
  --color-text:     #E8E8E8;
  --color-grayish:  #DBDBDB;
  --color-gray:     #C6C6C6;
  --color-darkened: #a8a8a8;
  --color-muted:    #8c8c8c;
  --color-bg-text: #282828;

  --color-error:    #FF0000;

  --color-bg:        #1A1A1A;
  --color-bg-images: #1d1d1d;
  --color-bg-darker: #141414;
  --color-bg-dark:   #000; 
  --color-bg-transparent: rgba(20, 20, 20, 0.7);

  //select
  --color-bg-select: #424242;
  --color-active-option: #323232;
}